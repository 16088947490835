import FooterContainer_DoubleBorderScreenComponent from '@wix/thunderbolt-elements/src/components/FooterContainer/viewer/skinComps/DoubleBorderScreen/DoubleBorderScreen.skin';


const FooterContainer_DoubleBorderScreen = {
  component: FooterContainer_DoubleBorderScreenComponent
};


export const components = {
  ['FooterContainer_DoubleBorderScreen']: FooterContainer_DoubleBorderScreen
};


// temporary export
export const version = "1.0.0"
